@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.font-display {
  font-family: 'Raleway';
}

@layer utilities {
  .filter-pgray-300 {
    filter: grayscale(100%) invert(85%) sepia(12%) saturate(298%) hue-rotate(174deg) brightness(93%) contrast(92%);
    -webkit-filter: grayscale(100%) invert(85%) sepia(12%) saturate(298%) hue-rotate(174deg) brightness(93%) contrast(92%);
  }

  .filter-pgray-400 {
    filter: grayscale(100%) invert(67%) sepia(18%) saturate(423%) hue-rotate(175deg) brightness(94%) contrast(82%);
    -webkit-filter: grayscale(100%) invert(67%) sepia(18%) saturate(423%) hue-rotate(175deg) brightness(94%) contrast(82%);
  }

  .filter-pgray-500 {
    filter: grayscale(100%) invert(42%) sepia(21%) saturate(582%) hue-rotate(176deg) brightness(96%) contrast(90%);
    -webkit-filter: grayscale(100%) invert(42%) sepia(21%) saturate(582%) hue-rotate(176deg) brightness(96%) contrast(90%);
  }

  /* .marquee-text-wrap {
    overflow: hidden
  } */

  .w-infinity {
    width: 100000px
  }

  .scroll-left-animation {
    -webkit-animation-name: scroll-animation;
    animation-name: scroll-animation;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    float: left
  }

  .animation-dur-40s {
    -webkit-animation-duration: 40000ms;
    animation-duration: 40000ms;
  }
}


  /* .marquee-text-paused .marquee-text-text {
    -webkit-animation-play-state: paused;
    animation-play-state: paused
  } */

@-webkit-keyframes scroll-animation {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
  }
}

@keyframes scroll-animation {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
  }
}